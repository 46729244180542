// @flow

import React from "react";
import {
    Layout,
    withPrivateRoute,
    AdminUserManagementLayout,
} from "@containers";
import { RegionalDistribution as _RegionalDistribution } from "@containers";

/**
 * RegionalDistribution
 */
const RegionalDistribution = (props: *) => (
    <Layout {...props}>
        <AdminUserManagementLayout>
            <_RegionalDistribution
                id="regioverdeling"
                location={props.location}
            />
        </AdminUserManagementLayout>
    </Layout>
);

export default withPrivateRoute(RegionalDistribution);
